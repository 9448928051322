import * as React from "react";
import { Layout, Cta, Splash, FullPortfolioCard } from "components";
import { theme } from "theme";
import { graphql } from "gatsby";

const alternateColors = [theme.secondaryGrey, theme.offBlack];

const Work = ({ data }) => {
  const portfolio_content = data.allContentfulPortfolio.edges;
  return (
    <Layout>
      <Splash
        sectionSubtitle="Projects"
        sectionTitle="Recent Work"
        sectionText="For me, the greatest reward that I receive from completing a project, are the lessons that I learned. I view all of my projects are learning opportunities, and look forward to self-developing and strengthening from every client that I serve."
        type="work"
      />
      {portfolio_content &&
        portfolio_content.map(({ node }, index) => {
          const alternatingIndex = index % 2;
          const backgroundColor = alternateColors[alternatingIndex];
          return (
            <FullPortfolioCard
              key={index}
              slug={node.slug}
              title={node.title}
              gradientStart={node.gradient[0]}
              gradientEnd={node.gradient[1]}
              requirements={node.requirements}
              description={node.description?.description}
              productType={node.productType}
              backgroundColor={backgroundColor}
              alternatingIndex={alternatingIndex}
              img={node.splash}
            />
          );
        })}
      <Cta />
    </Layout>
  );
};

export const query = graphql`
  query {
    allContentfulPortfolio(sort: { order: ASC, fields: order }) {
      edges {
        node {
          ...PortfolioFragment
        }
      }
    }
  }
`;

export default Work;
